export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'
export const USER_GET_BALANCE = 'USER_GET_BALANCE'
export const SET_ADVENTURES = 'SET_ADVENTURES';
export const TOKEN_TOPUP_REQUEST = 'TOKEN_TOPUP_REQUEST';
export const TOKEN_TOPUP_PENDING = 'TOKEN_TOPUP_PENDING';
export const TOKEN_TOPUP_COMPLETE = 'TOKEN_TOPUP_COMPLETE';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const TOGGLE_HAMBURGER = 'TOGGLE_HAMBURGER';
export const SET_PROFILE = 'SET_PROFILE';

import { USER_LOGGED_IN, USER_GET_BALANCE, USER_LOGGED_OUT } from '../constants';

const initialState = {
  data: null,
  authenticated: false,
  balance: 0,
}

const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case USER_GET_BALANCE:
      return {
        ...state,
        balance: action.payload
      };

    case USER_LOGGED_IN:
      return {
        ...state,
        data: action.payload,
        authenticated: true,
      };

    case USER_LOGGED_OUT:
      return {
        ...state,
        data: null,
        authenticated: false,
      }

    default:
      return state;
  }
}

export default userReducer

import React, { Component } from 'react';
import styled from "styled-components";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Loadable from 'react-loadable';
import Loading from '../Loading';

import HamburgerMenu from '../HamburgerMenu';
import ScrollToTop from "../ScrollToTop";
import NavBar from '../../containers/NavBar';

const LoadWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
`;

const WrappedLoading = () => <LoadWrap>
  <Loading />
</LoadWrap>;

const AdventureHome = Loadable({
  loader: () => import('../../containers/AdventureHome'),
  loading: WrappedLoading
});
const Home = Loadable({
  loader: () => import('../../containers/Home'),
  loading: WrappedLoading
});
const Login = Loadable({
  loader: () => import('../../containers/Login'),
  loading: WrappedLoading
});
const SummaryPage = Loadable({
  loader: () => import('../../containers/SummaryPage'),
  loading: WrappedLoading
});
const BookingConfirmation = Loadable({
  loader: () => import('../../containers/BookingConfirmation'),
  loading: WrappedLoading
});
const UserProfile = Loadable({
  loader: () => import('../../containers/UserProfile'),
  loading: WrappedLoading
});
const OtherUsersProfile = Loadable({
  loader: () => import('../../containers/OtherUsersProfile'),
  loading: WrappedLoading
});

function PrivateRoute({ component: Component, authed, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          render ? render(props) : <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authed, render, ...rest }) {
  return <Route {...rest} render={props => (authed === false ? (render ? render(props) : <Component {...props} />) : <Redirect to="/" />)} />;
}

class App extends Component {
  componentDidMount() {
    this.props.updateAdventures();
  }

  render() {
    const { authed, toggleHamburger, hamburgerActive } = this.props;
    return (
      <main onClick={() => hamburgerActive && toggleHamburger()}>
        <Router>
          <div>
            {authed &&
              <NavBar />
            }
            <Route path="/" component={ScrollToTop} />
            {authed && <HamburgerMenu active={hamburgerActive} />}
            <Switch>
              <PublicRoute authed={authed} exact path="/login" component={Login} />
              <PrivateRoute authed={authed} exact path="/me" component={UserProfile} />
              <PrivateRoute authed={authed} exact path="/profile/:id" component={OtherUsersProfile} />
              <PrivateRoute authed={authed} exact path="/adventure/:id" component={AdventureHome} />
              <PrivateRoute authed={authed} exact path="/adventure/:id/summary" component={SummaryPage} />
              <PrivateRoute authed={authed} exact path="/adventure/:id/confirmation" render={props => <BookingConfirmation toggleHamburger={toggleHamburger} {...props}  />} />
              <PrivateRoute authed={authed} path="/" component={Home} />
            </Switch>
          </div>
        </Router>
      </main>
    );
  }
}

export default App;

import { TOKEN_TOPUP_REQUEST, TOKEN_TOPUP_PENDING, TOKEN_TOPUP_COMPLETE, SET_TRANSACTIONS } from '../constants';

const initialState = {
  buyingInProgress: false,
  confirmingInProgress: false,
  transactions: []
}

const tokenReducer = (state = initialState, action) => {
  switch(action.type) {
    case TOKEN_TOPUP_REQUEST:
      return {
        ...state,
        confirmingInProgress: true
      }

    case TOKEN_TOPUP_PENDING:
      return {
        ...state,
        buyingInProgress: true,
        confirmingInProgress: false
      };

    case TOKEN_TOPUP_COMPLETE:
      return {
        ...state,
        buyingInProgress: false,
        confirmingInProgress: false
      };

    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions
      };

    default:
      return state;
  }
}

export default tokenReducer

import { TOGGLE_HAMBURGER } from '../constants';

const initialState = {
  hamburgerActive: false,
}

const appReducer = (state = initialState, action) => {
  switch(action.type) {
    case TOGGLE_HAMBURGER:
      return {
        ...state,
        hamburgerActive: !state.hamburgerActive
      }

    default:
      return state;
  }
}

export default appReducer

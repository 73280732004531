import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import { toggleHamburger } from '../../actions/app';

const mapStateToProps = ({ user }) => ({
  avatar: user && user.data && user.data.avatar ? user.data.avatar.uri : null
});

const mapDispatchToProps = (dispatch) => ({
  toggleHamburger: bindActionCreators(toggleHamburger, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutUser } from '../../actions/user';

const Burger = styled.div`
  background: rgb(160, 212, 220);
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  box-sizing: border-box;
  color: #fff;
  width: 90vw;
  max-width: 350px;
  height: 100vh;
  padding: 30px;
  position: fixed;
  left: -105vw;
  transition: transform 1s ease-in-out;
  transform: translate(${props => props.active ? "105vw" : "0"});
  z-index: 13;
  & ul {
    margin-top: 50px;
  }
  & ul, & li {
    list-style: none;
    padding: 0;
  }
  & ul li a {
    color: #fff;
    display: block;
    font-family: 'Varela Round', sans-serif;
    margin: 20px auto;
    text-decoration: none;
  }
`;

const HamburgerMenu = ({ active, onLogout }) => <Burger active={active}>
  <h2>Adventure Starter</h2>
  <ul>
    <li><Link to="/">Home</Link></li>
    <li><Link to="/me">Profile</Link></li>
    <li><Link onClick={onLogout} to="/">Log Out</Link></li>
  </ul>
</Burger>

const mapDispatchToProps = dispatch => ({
  onLogout: bindActionCreators(logoutUser, dispatch),
});

export default connect(null, mapDispatchToProps)(HamburgerMenu);

import { combineReducers } from 'redux';
import userReducer from './userReducer';
import tokenReducer from './tokenReducer';
import adventureReducer from "./adventureReducer";
import appReducer from './appReducer';
import profileReducer from './profileReducer';

const reducer = combineReducers({
  adventures: adventureReducer,
  tokens: tokenReducer,
  user: userReducer,
  app: appReducer,
  currentProfile: profileReducer
})

export default reducer

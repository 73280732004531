import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import { Provider } from 'react-redux'
import { injectGlobal } from 'styled-components';
import store from './store';
import { unregister } from './utils/registerServiceWorker';

// eslint-disable-next-line
injectGlobal`
  html, body, main {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  html, body {
    height: 100%;
  }
  h1, h2, h3, h4, h5, p {
    font-family: 'Varela Round', sans-serif;
  }
  #uport-qr > div > div:nth-child(2) > div > img {
    width: 100%;
  }
`;

ReactDOM.render((
  <Provider store={store}>
    <App />
  </Provider>
  ),
  document.getElementById('root')
);

unregister();
import { Connect, SimpleSigner } from 'uport-connect';
import { Credentials } from 'uport';
import Web3 from 'web3';

export const uport = new Connect('Adventure Starter', {
  clientId: '2owY8syuJs7D2UUaLNoW7VkLm8bW9BTj7Gb',
  network: 'rinkeby',
  signer: SimpleSigner('e93c83b6ad242c501287523d2f8b91e36d382ca1475b4800b1c2148412e36ba7')
});
export const appCredentials = new Credentials({
  address: '2owY8syuJs7D2UUaLNoW7VkLm8bW9BTj7Gb'
});

const provider = uport.getProvider()
export const web3 = new Web3(provider)
import AdventureTokenContract from './AdventureTokenContract'
import checkAddressMNID from './checkAddressMNID'
import waitForMined from './waitForMined';

export const getBalanceHelper = addr => {
  return new Promise((resolve, reject) => {
    const address = checkAddressMNID(addr);
    AdventureTokenContract.balanceOf
      .call(address, (error, balance) => {
        if (error) {
          reject(error);
        }
        resolve(balance.toNumber());
      })
  });
};

export const topupBalanceHelper = (addr, value, pendingCallback) => {
  return new Promise((resolve, reject) => {
    const address = checkAddressMNID(addr);
    AdventureTokenContract.buyTokens(value, { from: address } , (error, txHash) => {
        if (error) {
          reject(error);
        }
        waitForMined(address, txHash, { blockNumber: null },
          () => {
            // Pending transaction - just wait.
            pendingCallback();
          },
          () => {
            console.log('waitForMined complete')
            resolve(address);
          }
        )
      })
  });
};

import React from 'react';
import styled from "styled-components";

const COLOURS = {
  standard: "57,201,199",
  inverted: "255, 255, 255"
}

const Loader = styled.div`
  &, &:after {
    border-radius: 50%;
    width: ${props => props.loaderWidth || "10em"};
    height: ${props => props.loaderHeight || "10em"};
  }
  & {
    margin: 10px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: ${props => props.loaderThickness || "1.1em"} solid rgba(${props => props.inverted ? COLOURS.inverted : COLOURS.standard}, 0.2);
    border-right: ${props => props.loaderThickness || "1.1em"} solid rgba(${props => props.inverted ? COLOURS.inverted : COLOURS.standard}, 0.2);
    border-bottom: ${props => props.loaderThickness || "1.1em"} solid rgba(${props => props.inverted ? COLOURS.inverted : COLOURS.standard}, 0.2);
    border-left: ${props => props.loaderThickness || "1.1em"} solid rgb(${props => props.inverted ? COLOURS.inverted : COLOURS.standard});
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Loading = (props) => {
  return (
    <Loader {...props}>
      Loading
    </Loader>
  );
};

export default Loading;

import { USER_LOGGED_IN, USER_GET_BALANCE, USER_LOGGED_OUT } from '../constants';
import { getBalanceHelper } from '../utils/adventureTokenHelpers';

export function loginUser(user) {
  return {
    type: USER_LOGGED_IN,
    payload: user
  }
}

function getBalance(balance) {
  return {
    type: USER_GET_BALANCE,
    payload: balance
  }
}

function userLoggedOut(user) {
  return {
    type: USER_LOGGED_OUT,
    payload: user
  }
}

export function logoutUser(history) {
  return function(dispatch) {
    dispatch(userLoggedOut())
    return history.push('/')
  }
}

export function getUserBalance(address) {
  return function(dispatch) {
    getBalanceHelper(address).then(balance => {
      dispatch(getBalance(balance));
    })
  }
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from '../../components/App';
import { getAdventures, hydrateAdventuresWithActivities } from "../../utils/adventureStorageHelpers";
import { SET_ADVENTURES } from "../../constants";
import { toggleHamburger } from '../../actions/app';

const updateAdventures = dispatch => () => getAdventures()
  .then(hydrateAdventuresWithActivities)
  .then(adventures => dispatch({
    type: SET_ADVENTURES,
    payload: adventures
  }))

const mapStateToProps = ({ user, app }) => ({
  authed: user.authenticated,
  user: user,
  hamburgerActive: app.hamburgerActive
});

const mapDispatchToProps = (dispatch) => ({
  updateAdventures: updateAdventures(dispatch),
  toggleHamburger: bindActionCreators(toggleHamburger, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { web3 } from './uportSetup'

function AdventureTokenContractSetup () {
  let AdventureTokenABI = web3.eth.contract(
    require("./AdventureCoin.json").abi
  );
  let AdventureTokenContractObj = AdventureTokenABI.at(process.env.REACT_APP_CONTRACT_ADDRESS || '0x40bcdb16c46ffbb38804a100cbd86d4161552ca4');
  return AdventureTokenContractObj;
}

const AdventureTokenContract = AdventureTokenContractSetup();
console.log(AdventureTokenContract);

export default AdventureTokenContract;


import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import rootReducer from './reducers'

const enhancers = compose(
  applyMiddleware(thunk),
  typeof window === 'object' && typeof window.devToolsExtension !== 'undefined' ? window.devToolsExtension() : f => f
);

export default createStore(rootReducer, {}, enhancers);

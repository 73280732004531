import AdventureTokenContract from './AdventureTokenContract';
import checkAddressMNID from './checkAddressMNID';
import waitForMined from './waitForMined';

export const getAdventureCount = () => {
  return new Promise((resolve, reject) => {
    AdventureTokenContract.getAdventureCount
      .call((error, count) => {
        if (error) {
          reject(error);
        }
        resolve(count);
      })
  });
};

export const getAdventureByIndex = (index) => {
  return new Promise((resolve, reject) => {
    AdventureTokenContract.getAdventureByIndex
      .call(index, (error, adventure) => {
        if (error) {
          reject(error);
        }
        resolve({
          adventureId: adventure[0],
          creator: adventure[1],
          numberOfActivities: adventure[2]
        });
      })
  });
};

export const getAdventures = () => {
  return getAdventureCount()
    .then(count => {
      let requests = [];
      for (let i = 0; i < count; i++) {
        requests.push(getAdventureByIndex(i));
      }
      return Promise.all(requests);
    })
};

export const getActivityByIndexForAdventure = (adventure, index) => {
  return new Promise((resolve, reject) => {
    AdventureTokenContract.getCuratedActivityForAdventure
      .call(adventure.adventureId, index, (error, activity) => {
        if (error) {
          reject(error);
        }
        resolve({
          activityId: activity[0],
          cost: activity[1],
          providerAddress: activity[2],
          curatorAddress: activity[3]
        });
      })
  });
}

export const getActivitiesForAdventure = (adventure) => {
  let activityCount = adventure.numberOfActivities.toNumber();
  let requests = [];
  for (let i = 0; i < activityCount; i++) {
    requests.push(getActivityByIndexForAdventure(adventure, i));
  }
  return Promise.all(requests);
};

export const hydrateAdventuresWithActivities = (adventures) => Promise.all(adventures.map(adventure =>
  getActivitiesForAdventure(adventure).then(activities => Object.assign({}, adventure, {
    activities
  }))
));


export const bookAdventure = (addr, adventureId, pendingCallback) => {
  return new Promise((resolve, reject) => {
    const address = checkAddressMNID(addr);
    AdventureTokenContract.bookAdventure(adventureId, { from: address } , (error, txHash) => {
        if (error) {
          reject(error);
        }
        waitForMined(address, txHash, { blockNumber: null },
          () => {
            // Pending transaction - just wait.
            pendingCallback();
          },
          () => {
            console.log('waitForMined complete')
            resolve(address);
          }
        )
      })
  });
};

import React from "react";
import styled from "styled-components";

const Header = styled.header`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: absolute;
  height: 30px;
  margin-top: 18px;
  width: 100%;
  padding: 0 22px;
  z-index: ${props => props.overlay ? "11" : "10"};
  ${props => props.overlay ? `{
    background: #fff;
  }` : ``}
  & img {
    height: 100%;
    border-radius: 50%;
  }
  & .hamburger {
    color: ${props => props.black ? "#000" : "#fff"};
    font-size: 26px;
  }
  & h3 {
    color: #2a2a2a;
    font-size: 18px;
    position: absolute;
    left: 50%;
    margin: 0;
    transform: translate(-50%);
  }
  & .backIcon {
    border: 2px solid #000;
    border-right: none;
    border-bottom: none;
    height: 8px;
    width: 8px;
    transform: rotate(-45deg);
  }
`;

const Avatar = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export default ({avatar, back, black, history, title, toggleHamburger, overlay}) => {
  const icon = avatar ? avatar : require("../../images/mei-profile.jpg")
  return (
    <Header overlay={overlay} black={black}>
      {!back && <span className="hamburger" onClick={() => toggleHamburger()}>&#9776;</span>}
      {back && <span className="backIcon" onClick={() => history.goBack()}></span>}
      {title && <h3>{title}</h3>}
      <Avatar alt="avatar" src={icon} onClick={() => history.push('/me')} />
    </Header>
  )
};

import { SET_ADVENTURES } from '../constants';

const initialState = {
}

const adventureReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_ADVENTURES:
      return action.payload.reduce((adventures, adventure) => Object.assign({}, adventures, {
        [adventure.adventureId]: adventure
      }), {});
    default:
      return state;
  }
}

export default adventureReducer;
